<template>
  <v-container>
    <layout :back="true" />
    <v-row>
      <v-col v-if="type == 'candidate'">
        <candidate-configuration />
      </v-col>
      <v-col v-else>
        <pharmacy-configuration />
      </v-col>
    </v-row>
  </v-container>
</template>
<script>
//layout
import Layout from "@/components/layouts/Profile.vue";
// Configurations components
import CandidateConfiguration from "@/components/configuration/CandidateConfiguration.vue";
import PharmacyConfiguration from "@/components/configuration/PharmacyConfiguration.vue";

export default {
  components: {
    Layout,
    CandidateConfiguration,
    PharmacyConfiguration
  },
  data() {
    return {
      type: "candidate"
    };
  }
};
</script>
